/*
- The base layer is for things like reset rules or default styles applied to plain HTML elements.
- The components layer is for class-based styles that you want to be able to override with utilities.
- The utilities layer is for small, single-purpose classes that should always take precedence over any other styles.
*/

@import "./base/document.css";
@import "./base/fonts.css";
@import "./base/headings.css";
@import "./base/print.css";

@import "./components/colors.css";
@import "./components/editor.css";
@import "./components/form.css";

@import "./utilities/utilities.css";

@tailwind base;
@tailwind components;
@tailwind utilities;
